import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, navigate, useStaticQuery, withPrefix } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"
import ReactMarkdown from "react-markdown/with-html"
import Slider from "react-slick"

import { SMART_MOVE_FIND_BY_LOCATION_AND_TYPE } from "../graphql/smartmove-graphql"

import "../graphql/fragments"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { Section, Container } from "../components/grid/grid"
import { CardProject } from "../components/card-project/card-project"
import icLocationBlack from "../assets/img/icons/icLocationBlack.png"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const changeParentSlug = (slug) => {
  switch(slug) {
    case "infiniteliving-shophouse":
      slug = "shophouse";
      break;
    case "infiniteliving-residential":
      slug = "residential";
      break;
    case "infiniteliving-kavling":
      slug = "kavling";
      break;
    case "infiniteliving-apartment":
      slug = "apartment";
      break;
  }
  return slug
}

const getLocationsList = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

const ascName = (a, b) => {
  const nameA = a.value
  const nameB = b.value

  let comparison
  if (nameA > nameB) {
    comparison = 1
  } else if (nameA < nameB) {
    comparison = -1
  }

  return comparison
}

const ProjectByLocationInfiniteLiving = ({ pageContext, location }) => {

  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        propertyType(id: "infiniteliving", idType: SLUG) {
          name
          description
          children {
            nodes {
              name
              slug
              projects(first:1000){
                nodes {
                  slug
                  title
                  excerpt
                  featuredImage {
                    sourceUrl
                  }
                  priceProduct {
                    price
                  }
                  locations {
                    nodes {
                      name
                      slug
                    }
                  }
                  propertyTypes(first: 200) {
                    nodes {
                      name
                      slug
                      propertyTypeId
                      children {
                        nodes {
                          name
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        facilities(first: 100) {
          nodes {
            name
            slug
            facilityId
            projects {
              nodes {
                propertyTypes {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
          }
        }

        locations(first: 100) {
          nodes {
            locationId
            slug
            name
          }
        }

        propertyTypes(first: 100) {
          nodes {
            name
            slug
            propertyTypeId
            children {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  const wordPress = staticData?.wordPress?.propertyType
  const listPropertyTypesParent = [
    { label: "All Property", value: "all-property" },
    { label: "Apartment", value: "apartment" },
    { label: "Kavling", value: "kavling" },
    { label: "Residential", value: "residential" },
    { label: "Shophouse", value: "shophouse" },
  ]

  let listLocationStatic = []

  wordPress?.children?.nodes?.forEach(child => {
    child?.projects?.nodes?.forEach(project => {
      project?.locations?.nodes?.forEach(loc => {
        listLocationStatic.push({label: loc.name, value: loc.slug})
      })
    })
  })

  const locations = getLocationsList(listLocationStatic, 'value')
  // let locations = [
  //   { label: "All Locations", value: "all" },
  //   { label: "Balikpapan", value: "balikpapan" },
  //   { label: "Batam", value: "batam" },
  //   { label: "BSD City", value: "bsd-city" },
  //   { label: "Cibubur", value: "cibubur" },
  //   { label: "Jakarta", value: "jakarta" },
  //   { label: "Surabaya", value: "surabaya" },
  //   { label: "Tangerang", value: "tangerang" },
  // ]

  const {
    locationId,
    typeId,
    locationName,
    typeName,
    locationSlug,
    parentSlug,
  } = pageContext
  const { data: projectsByLocation, loading } = useQuery(
    SMART_MOVE_FIND_BY_LOCATION_AND_TYPE,
    {
      variables: { locationId, typeId, parentId: typeId },
    }
  )
  const resizeScreen = useMediaPredicate("(min-width: 992px)")

  const masterProjects = []
  const duplicatedProject = []
  let staticProjects = []
  projectsByLocation?.projects?.nodes.forEach((item) => {
    masterProjects.push({ ...item, catName: typeName })
  })

  wordPress?.children?.nodes.forEach(children =>
    children.projects.nodes.forEach(item =>{
      let slug = changeParentSlug(children.slug)
      duplicatedProject.push({...item, catName: children.name, catSlug: slug})
      staticProjects.push({...item, catName: children.name, catSlug: slug})
    })
  )

  const offset = 0
  const limit = 12
  const perPageRecommended = 6
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(limit)
  const [listLocations, setListLocations] = useState(locations.sort(ascName))
  const [filteredProjects, setFilteredProjects] = useState()
  const [recommendedProjects, setRecommendedProjects] = useState()
  const [filteredLocations, setFilteredLocations] = useState(
    locations.filter((item) => item.label === locationName)[0]
  )
  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState(
    listPropertyTypesParent.filter((item) => item.label === typeName).length > 0
      ? listPropertyTypesParent.filter((item) => item.label === typeName)[0]
      : { label: "All Property", value: "all-property" }
  )
  const [filterKey, setFilterKey] = useState("")

  let listOfImages = []
  let listImagesBanner =
    projectsByLocation?.projects.nodes[0].locations?.nodes[0].acfProjectLocation
      ?.sliderImageBannerWdesc
  let listImagesMobile =
    projectsByLocation?.projects.nodes[0].locations?.nodes[0].acfProjectLocation
      ?.sliderImageMobileWdesc

  if (resizeScreen) {
    listImagesBanner &&
      listImagesBanner.map((item) => {
        listOfImages.push({
          image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
          url: item?.link?.url ? item?.link?.url : "#",
          title: item?.title ? item.title : "",
          description: item?.description ? item?.description : "",
        })
      })
  } else {
    listImagesMobile &&
      listImagesMobile.map((item) => {
        listOfImages.push({
          image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
          url: item?.link?.url ? item?.link?.url : "#",
          title: item?.title ? item?.title : "",
          description: item?.description ? item?.description : "",
        })
      })
  }

  useLayoutEffect(() => {
    setFilteredProjects(masterProjects)
    setRecommendedProjects(duplicatedProject) 
  }, [loading])

  useEffect(() => {
    if (
      filteredPropertyTypes.value !== undefined &&
      filteredLocations.value !== undefined
    ) {
      if (filteredLocations.value === "all") {
        if (filteredPropertyTypes.value === "all-property") {
          navigate("/infiniteliving/")
        } else {
          navigate("/infiniteliving/" + filteredPropertyTypes.value)
        }
      } else {
        navigate(
          "/infiniteliving/" +
            filteredPropertyTypes.value +
            "/" +
            filteredLocations.value
        )
      }
    } else if (
      filteredPropertyTypes.value !== undefined &&
      filteredLocations.value === undefined
    ) {
      if (filteredPropertyTypes.value === "all-property") {
        navigate("/infiniteliving/")
      } else {
        navigate("/infiniteliving/" + filteredPropertyTypes.value, {
          state: {
            propertyType: filteredPropertyTypes.value,
          },
        })
      }
    } else if (
      filteredPropertyTypes.value === undefined &&
      filteredLocations.value !== undefined
    ) {
      if (filteredLocations.value === "all") {
        navigate("/infiniteliving/all-property/all-locations")
      } else {
        navigate("/infiniteliving/all-property/" + filteredLocations.value, {
          state: {
            location: filteredLocations.value,
          },
        })
      }
    }
  }, [filteredLocations])

  useEffect(() => {
    let listOfImages = []
    let listImagesBanner =
      projectsByLocation?.projects.nodes[0].locations?.nodes[0]
        .acfProjectLocation?.sliderImageBannerWdesc
    let listImagesMobile =
      projectsByLocation?.projects.nodes[0].locations?.nodes[0]
        .acfProjectLocation?.sliderImageMobileWdesc

    if (resizeScreen) {
      listImagesBanner &&
        listImagesBanner.map((item) => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item.title : "",
            description: item?.description ? item?.description : "",
          })
        })
      document.querySelector(".animated").style.marginTop = "0px"
    } else {
      listImagesMobile &&
        listImagesMobile.map((item) => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item.title : "",
            description: item?.description ? item?.description : "",
          })
        })
      document.querySelector(".animated").style.marginTop = "60px"
    }

    // document.querySelector(".animated").style.marginTop = "0px"
    // document.querySelector(".sliderWfh").style.paddingTop = "0px"
    // document.querySelector(".sliderWfh").style.paddingBottom = "0px"
    // document.querySelector(".form-select .select__control").style.background ="transparent"
    document.querySelector("#lang-wrapper").classList.remove("d-flex")
    document.querySelector("#lang-wrapper").classList.add("d-none")
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    const locPathName = location.pathname
    let pathIdentifier = "/infiniteliving/"

    if (locPathName.indexOf(pathIdentifier) > -1) {  
      if(!resizeScreen){
        document.querySelector("#navbarMobile .logo-secondary").style.cssText = `display:none !important`
        document.querySelector("#navbarMobile .logo-main").style.cssText = `display:block !important`
      }else{
        document.querySelector("#desktopNav .logo-secondary").style.cssText = `display:none !important`
        document.querySelector("#desktopNav .logo-main").style.cssText = `display:block !important`
      }
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }
  }, [])

  const locationHandler = (e) => {
    setFilteredLocations(e)
  }
  const propertyTypesHandler = (e) => {
    if (e.value === "all-property") {
      navigate("/infiniteliving/")
    } else {
      navigate("/infiniteliving/" + e.value)
    }
  }

  const handleLoadMore = () => {
    const nextPage = page + 1
    const nextPerPage = nextPage * limit
    setPage(nextPage)
    setPerPage(nextPerPage)
  }

  const handlerFilterProject = () => {
    let projects
    let duplicatedList

    if (filterKey != "") {
      projects = masterProjects.filter(
        (item) => item.title.toLowerCase().indexOf(filterKey.toLowerCase()) > -1
      )
      if (projects.length === 0 ) {
        duplicatedList = duplicatedProject.filter(
          (item) => item.title.toLowerCase().indexOf(filterKey.toLowerCase()) > -1
        )
      }
    } else {
      projects = masterProjects
    }
    setFilteredProjects(projects)
    setRecommendedProjects(duplicatedList)
  }

  const settings = {
    autoplay: listOfImages.length > 1 ? true : false,
    autoplaySpeed: listOfImages.length > 1 ? 3000 : 0,
    accessibility: true,
    dots: true,
    dotsClass: "slick-dots dots-lg",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  }

  useEffect(() => {
    if (typeName) {
      if (sessionStorage.getItem("locations")) {
        const sessLocations = JSON.parse(sessionStorage.getItem("locations"))
        for (const key in sessLocations) {
          if (typeName == key && sessLocations.hasOwnProperty(typeName)) {
            const location = sessLocations[key]
            setListLocations([
              { label: "All Locations", value: "all" },
              ...location,
            ])
          }
        }
      }
    }
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO title="Infinite Living" />
      <Helmet>
        <script src={withPrefix("slider.js")} type="text/javascript" />
        <body className="bd-page" />
      </Helmet>
      {/* <CoverMain
        img={
          resizeScreen
            ? projectsByLocation?.projects?.nodes[0]?.locations?.nodes[0]
                ?.acfProjectLocation?.banner?.sourceUrl
            : projectsByLocation?.projects?.nodes[0]?.locations?.nodes[0]
                ?.acfProjectLocation?.mobileBanner?.sourceUrl
        }
        alt="Image Properti"
        className="cover-md cover-location"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain> */}
      <Section className="header-smartmove-banner p-0" style={{ padding: "0px" }}>
        <Slider {...settings}>
          {listOfImages.length > 0 &&
            listOfImages.map((item, i) => {
              return (
                <div key={i}>
                  <img
                    className="slider-img-smartmove w-100"
                    src={item.image}
                    onClick={() => window.open(item.url, "_blank")}
                  />
                  <Section>
                    <Container className="container-md">
                      <h2>{item.title}</h2>
                      <p>
                        <ReactMarkdown
                          key={item.description}
                          source={item.description}
                          escapeHtml={false}
                        />
                      </p>
                    </Container>
                  </Section>
                </div>
              )
            })}
        </Slider>
      </Section>
      <Section className="main-by-locations">
        <Container className="mb-3">
          <div className="row">
            <div className="col-md-12">
              <Container id="search-bar" className="mb-5">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    id="search-properti"
                    className="form-control"
                    placeholder="Cari properti disini"
                    value={filterKey}
                    onChange={(e) => setFilterKey(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => handlerFilterProject()}
                    >
                      Cari
                    </button>
                  </div>
                </div>
              </Container>
            </div>
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-landmark form-icon-fa"></i>
                <Select
                  value={filteredPropertyTypes}
                  id="propertytypes"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Property Types"
                  options={listPropertyTypesParent}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={(e) => propertyTypesHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-map-marker-alt form-icon-fa"></i>
                <Select
                  value={filteredLocations}
                  id="location"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Location"
                  options={listLocations}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={(e) => locationHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-3">
              <ul className="faq-nav">
                <li className="item">
                  <a
                    className="faq-link"
                    href="/infiniteliving/faq"
                    style={{ color: "#ab0204 !important" }}
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
        <div className="smartmove-list-projects">
          <div className="smartmove-bg"></div>
          {filteredProjects && (
            <div className="row col-md-12 m-0 p-0">
              {filteredProjects.slice(offset, perPage).map((item) => {
                let detailProjectUrl = `${location.pathname}`
                let catName = item.catName
                if (parentSlug) {
                  let catSlug
                  item.propertyTypes.nodes.forEach((v) => {
                    if (v.slug !== "infiniteliving") {
                      catName = v.name
                      catSlug = changeParentSlug(v.slug)
                    }
                  })
                  detailProjectUrl = `/infiniteliving/${catSlug}/${locationSlug}`
                }
                let ptChecker = ""
                staticProjects && staticProjects.forEach(element => {
                  if (element?.slug === item?.slug) {
                    element?.propertyTypes?.nodes?.forEach(propertTypeItem => {
                      if (propertTypeItem?.slug !== "infiniteliving") {
                        ptChecker = propertTypeItem.slug
                      }
                    })
                  }
                })
                return (
                  <div className="col-md-4 p-3">
                    <CardProject
                      key={item.title}
                      title={item.title}
                      description={
                        item.excerpt ||
                        "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                      }
                      img={
                        item.featuredImage
                          ? item.featuredImage.sourceUrl
                          : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg"
                      }
                      // link={`${detailProjectUrl}/${item.slug}`}
                      link={ptChecker !== "" && `/development/${ptChecker}/${item.slug}`}
                      className="px-sm-down-0 ratio-1-1"
                      category={catName}
                      location={item.locations.nodes[0].name || null}
                    />
                  </div>
                )
              })}
            </div>
          )}
          <div className="col-md-12 pb-5 text-center">
            {filteredProjects && filteredProjects.length > perPage ? (
              <button
                className="btn btn-primary w-100 w-md-25"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            ) : (
              ""
            )}
          </div>
          {filteredProjects && filteredProjects.length === 0 && (
            <div className="row">
              <div className="col-md-12 mb-5 not-available-text">The project not available</div>
              <div className="col-md-12 mt-3 nb-3 text-left recommended-text">Recommended For You</div>
              {recommendedProjects.slice(offset, perPageRecommended).map((item) => {
                return(
                  <div key={item.title} className="col-md-4 p-3">
                    <CardProject
                      title={item.title}
                      description={
                        item.excerpt ||
                        "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                      }
                      img={
                        item.featuredImage
                          ? item.featuredImage.sourceUrl
                          : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg"
                      }
                      link={`/infiniteliving/${item.catSlug}/${item.locations.nodes[0].slug}/${item.slug}`}
                      className="px-sm-down-0 ratio-1-1"
                      category={item.catName || null}
                      location={item.locations.nodes[0].name || null}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Section>
    </Layout>
  )
}
export default ProjectByLocationInfiniteLiving
