import React, { useContext } from "react"
// import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { LangContext } from "../../context/langContext"
import { AntiLink } from "../utils/utils"

import imgPlaceholder11 from "../../assets/img/common/img_placeholder_1-1.png"
import imgPlaceholder21 from "../../assets/img/common/img_placeholder_2-1.png"
import imgPlaceholder32 from "../../assets/img/common/img_placeholder_3-2.png"

import { replaceUnicode } from "../utils/utils"

export const CardProject = ({
  title,
  description,
  img,
  link,
  area,
  category,
  location,
  className,
  target,
  locationFrom,
}) => {
  const { lang } = useContext(LangContext)
  const categoryProjectIcon = () => {
    let categoryIcon = ""
    if (lang === "EN"){
      if (category.toLowerCase() === "doubledream property"){
        if(locationFrom === "/development/residential"){
          categoryIcon = "fal fa-home-lg-alt";
        }else if(locationFrom === "/development/office"){
          categoryIcon = "far fa-building";
        }else{
          categoryIcon = "fal fa-home-lg-alt";
        }
      }else if(category.toLowerCase() === "residential"){
        categoryIcon = "fal fa-home-lg-alt";
      }else if(category.toLowerCase() === "office"){
        categoryIcon = "far fa-building";
      }else if(category.toLowerCase() === "commercial"){
        categoryIcon = "fas fa-city";
      }else if(category.toLowerCase() === "township"){
        categoryIcon = "fas fa-city";
      }else{
        categoryIcon = "fal fa-home-lg-alt";
      }
    }else{
      if (category.toLowerCase() === "doubledream property"){
        if(locationFrom === "/id/development/residential"){
          categoryIcon = "fal fa-home-lg-alt";
        }else if(locationFrom === "/id/development/office"){
          categoryIcon = "far fa-building";
        }else{
          categoryIcon = "fal fa-home-lg-alt";
        }
      }else if(category.toLowerCase() === "residential"){
        categoryIcon = "fal fa-home-lg-alt";
      }else if(category.toLowerCase() === "(id) residential"){
        categoryIcon = "fal fa-home-lg-alt";
      }else if(category.toLowerCase() === "office"){
        categoryIcon = "far fa-building";
      }else if(category.toLowerCase() ==="commercial"){
        categoryIcon = "fas fa-city";
      }else if(category.toLowerCase() ==="township"){
        categoryIcon = "fas fa-city";
      }else{
        categoryIcon = "fal fa-home-lg-alt";
      }
    }
    return categoryIcon
  }
  return typeof img === "string" ? (
    <div className={`${className}`}>
      <AntiLink to={link} target={target}>
        <div
          // alt={title}
          className="card card-project"
          style={{
            background: `url(${img}) no-repeat center`,
            backgroundSize: "cover",
          }}
        >
          {/* <img 
            alt={title}
            data-src={img}
            data-srcset={`${img} 640w, ${img} 320w`}
            className="lazyload"
            width="100"
            height="100"
          /> */}
          <div className="card-body">
            <h3 className="card-title">{replaceUnicode(title)}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className={categoryProjectIcon()}></i> <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="far fa-building"></i>{" "}
                  <span>{area} hectares</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{location}</span>
                </div>
              )}
            </div>
          </div>
          <div className="card-overlay">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className={categoryProjectIcon()}></i> <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="far fa-building"></i>{" "}
                  <span>{area} hectares</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{location}</span>
                </div>
              )}
            </div>
            <span
              className="card-text"
              dangerouslySetInnerHTML={{ __html: description }}
            ></span>
            <button className="btn btn-link text-white fx-underline">
              {lang === "EN" ? "Explore More" : "Jelajahi Lebih Lanjut"}
            </button>
          </div>
          <img
            className="img-placeholder img-placeholder-1-1"
            src={imgPlaceholder11}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-2-1"
            src={imgPlaceholder21}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-3-2"
            src={imgPlaceholder32}
            alt="Placeholder"
            width="100"
            height="100"
          />
        </div>
      </AntiLink>
    </div>
  ) : (
    <div className={`${className}`}>
      <AntiLink to={link} target={target}>
        <BackgroundImage alt={title} className="card card-project" fluid={img}>
          <div className="card-body">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className={categoryProjectIcon()}></i> <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="far fa-building"></i> <span>{area}</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{location}</span>
                </div>
              )}
            </div>
          </div>
          <div className="card-overlay">
            <h3 className="card-title">{title}</h3>
            <div className="card-data">
              {category && (
                <div>
                  <i className={categoryProjectIcon()}></i> <span>{category}</span>
                </div>
              )}
              {area && (
                <div>
                  <i className="far fa-building"></i> <span>{area}</span>
                </div>
              )}
              {location && (
                <div>
                  <i className="fal fa-map-marker-alt"></i> <span>{location}</span>
                </div>
              )}
            </div>
            <p className="card-text">{description}</p>
            <button className="btn btn-link text-white fx-underline">
              {lang === "EN" ? "Explore More" : "Jelajahi Lebih Lanjut"}
            </button>
          </div>
          <img
            className="img-placeholder img-placeholder-1-1"
            src={imgPlaceholder11}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-2-1"
            src={imgPlaceholder21}
            alt="Placeholder"
            width="100"
            height="100"
          />
          <img
            className="img-placeholder img-placeholder-3-2"
            src={imgPlaceholder32}
            alt="Placeholder"
            width="100"
            height="100"
          />
        </BackgroundImage>
      </AntiLink>
    </div>
  )
}
